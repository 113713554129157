import type { Page } from '~~/types/api-common'

export const useRawProductService = () => {
    const listRawProducts = async (options: ListRawProductParams = {}): Promise<RawProduct[] | null> => {
        const params: Record<string, any> = {
            page: options.page || 1,
            size: options.size || 20,
            ...(
                (options.id_location && options.with_policies)
                    ? { id_location: options.id_location, with_policies: options.with_policies }
                    : {}
            ),
        }
        if (options.query) {
            const queryNormalized = options.query.normalize('NFD').replace(/[\u0300-\u036F]/g, '')
            params.q = queryNormalized
        }

        const data = await api<Page<any>>(
            '/raw_product/', { params },
        )

        const items = (options.id_location && options.with_policies)
            ? data?.items?.map((item: any) => ({
                ...item.RawProduct,
                product_policy: { ...item.RawProductPolicy || { quality_source: 'UNDEFINED' } },
            }))
            : data?.items

        return items || null
    }

    const getRawProductById = async (id: string): Promise<RawProduct | null> => {
        const data = await api<RawProduct>(
            `/raw_product/${id}`,
        )
        return data || null
    }

    return { listRawProducts, getRawProductById }
}

interface ListRawProductParams {
    size?: number
    page?: number
    query?: string
    with_policies?: boolean
    id_location?: string
}

export interface RawProduct {
    id_raw_product: string
    product_name: string
    catalog_raw_product_id: number
    average_unit_weight: number
    quantity_wholesale_unit: string
    quantity_wholesale: number
    ncm: string
    image?: string
    product_family: ProductFamily
    product_policy?: RawProductPolicy
}

interface ProductFamily {
    id_product_family: string
    product_family_name: string
    product_line: string
    product_category: string
    product_category_procurement: string
    maturation_sensitive: string
}

interface RawProductPolicy {
    quality_source: string
}
